@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --green: #0a686e;
  --green2: #042628;
  --brown: #D49A3A;

  --dark-brown: #39311D;
  --brown2: #39311D;
  --dark-brown-lite: #433C29;
  --title-font:"Patrick Hand", cursive;
  --text-font:"Poppins", sans-serif;
  --bg-1: #F3F0EB;
  --bg-2: #E9E4DB;
  --bg-3: #E0D8CB;
  
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--brown);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-brown);
}
::-webkit-scrollbar-track {
  background: var(--bg-2);
  border-radius: 10px;
}

.title {
  font-family: var(--text-font);
  font-size: 2rem;
  font-weight: 600;
  color: var(--brown)
}
.title2 {
  font-family: var(--title-font);
  font-size: 3rem;
  font-weight: 400;
  color: var(--brown2);
}
.btn-brown {
  background-color: white;
  border: 1px solid grey;
  margin: 0.1rem;
}
.btn-brown:nth-last-of-type(6) {
  background-color: var(--brown);
  color: white;
}
.btn-brown:hover {
  background-color: var(--brown);
  color: white;
}


.bg-brown {
  background-color: var(--bg-2);
}

body {
  background: var(--bg-2);
}