.contact_image {
    background-image: url('https://wallpaperaccess.com/full/1478026.jpg');
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 10px;
    box-sizing: border-box;
    outline: none;
    font-family: var(--text-font);
}
input:focus {
    border: 1px solid var(--brown)
}
textarea {
    width: 100%;
    height: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 10px;
    box-sizing: border-box;
    outline: none;
    font-family: var(--text-font);
    resize: none;
}
.contact_form textarea:focus {
    border: 1px solid var(--brown)
}