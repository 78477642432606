.Testimonal_section {
    background: url('https://loverowan.org/wp-content/uploads/2019/04/2011-acd-52.jpg');
    background-size: cover;
}
.Testimonal {
    background: rgba(5,64,68,0.8);
    height: 100%;
}
.Testimonal p:first-of-type {
    font-family: var(--text-font);
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}
.Testimonal h1 {
    font-family: var(--title-font);
    color: white;
    font-size: 4rem;
    font-weight: 600;
}
.testimonial_card {
    background: var(--bg-1);
    position: relative;

}
.testimonial_card P:first-of-type {
    font-size: 1rem;
    color: rgb(89, 88, 88);
    font-weight: 400;
}
.test-name {
    color: var(--brown);
    font-weight: 600;
}
.quote_icon {
    font-size: 3rem;
    background-color: var(--brown);
    color: white;
    padding: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(100%, -50%);
   
}
