.stat-background {
    background:url("https://cdn.theatlantic.com/assets/media/img/2014/07/14/opener_v02-2/1920.jpg?1440086868");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    backdrop-filter: blur(10px);
}
.stat-background2 {
    background-color: rgba(5,64,68,0.5);
    width: 100%;
    background-size: contain;

}
.Stat_section {
    min-height: 70vh;
    color: white;
}
.Stat_section h1:first-of-type {
    font-family: var(--title-font);
    font-size: 4rem;
}
.Stat_section p:first-of-type{
    font-weight: 400;
}
.Stat_section .stat {
    background-color: rgba(5,64,68,0.1);
    backdrop-filter: blur(10px);
    border: 1px solid white;
    border-radius: 15px;
    padding: 3rem;
    width: 100%;
    height: 100%;
}
.Stat_section .stat {
    font-size: 1rem;
    color: white;
}
.Stat_section .stat .ICON {
    font-size: 4rem;
    margin-bottom: 1rem;
}
.Stat_section .stat h1 {
    font-size: 2rem;
}