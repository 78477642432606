.Voluteer_container {
    background: var(--bg-1);
}
.Voluteer_container h1 {
    font-family: "Patrick Hand", cursive;
    font-size: 4rem;
    font-weight: 800;
    margin: 0 0 1rem 0;
    color: black;
}
.voluteer-box {
    margin-top: 4rem;
    padding-top: 6rem;
    position: relative;
    background: white;
    transition: 0.4s;
}
.valunteer-comment {
    color: gray;
}
.voluteer-box:hover{
    background-color: var(--green);
    color: black;
}

.voluteer-img img {
    width: 65%;
    height: 200px;
    position: absolute;
    border-radius: 50%;
    border: 5px solid var(--bg-1);
    object-fit: cover;
    transform: translate(-50%, -90%);
}
.voluteer-info ul {
    list-style: none;
    font-size: 2rem;
    margin: 0 0 1rem 0;
    padding: 0;
}
.voluteer-info ul li a {
    width: 100%;
}
.voluteer-info h1 {
    font-family: "Patrick Hand", cursive;
    font-size: 2.2rem;
    font-weight: 800;
    margin: 0 0 1rem 0;
    color: black;
}

.social_icon {
    font-size: 2.5rem;
    color: white;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: var(--brown);
    cursor: pointer;
}
.social_icon:hover {
    background-color: black;
}

@media screen and (max-width: 768px) {
    .voluteer-img img {
        width: 65%;
        height: 200px;
        position: absolute;
        border-radius: 50%;
        border: 5px solid var(--bg-1);
        object-fit: cover;
        transform: translate(-50%, -80%);
    }
}