.Brand_Name {
    font-size: 1.5rem;
    font-weight: 900;
}
.Brand_Name span {
    color: var(--brown);
}
.ul {
    list-style: none;
}
.nav-item {
    position: relative;
}
.nav-item::after {
    left: 0;
    position: absolute;
    content: "";
    background-color: var(--brown);
    height: 2px;
    width: 0%;
    transition: all 0.4s ease-in-out;
}

.nav-item:hover::after {
    width: 100%
}