.cause-Section {
    background: var(--bg-1);
}
.Cause_card {
    position: relative;
    background: var(--bg-1);
    transition: color 0.3s ease-in-out;
}
.Cause_card:hover {
    background: var(--bg-2);
    transition: all 0.3s ease-in-out;
}
.Cause_card:hover .fund_rate {
    background: var(--brown);
    transition: color 0.3s ease-in-out;
}
.Cause_card .btn-education {
    position: absolute;
    background-color: var(--brown);
    transform: translate(150%,-50%);
    border-radius: 12px;
    color: white;
    padding:  0.2rem 1rem;
    z-index: 2;
}
.Cause_card h5 {
    font-family: var(--title-font);
    font-size: 2rem;
    font-weight: 800;
}
.Cause_card p {
    font-family: var(--text-font);
    font-size: 1rem;
    color: var(--dark-brown-lite);
}
.Cause_card .rate-inner {
    width: 90%;
    height: 10px;
    border-radius: 5px;
    background-color: var(--bg-2);
    position: relative;
}

.Cause_card .rate-inner::after {
    content: "";
    width: 75%;
    height: 100%;
    background-color: var(--green);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}

.fund_rate {
    background: var(--bg-3);
    transition: all 0.3s ease-in-out;
}


