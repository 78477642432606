.Children_section {
   background: var(--bg-1);
}

.bg-image div {
    width: 100%;
    height: 500px;
    background-image: url('https://preview.colorlib.com/theme/lovecare/images/about.jpg.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: red;
}
/*https://preview.colorlib.com/theme/lovecare/images/about.jpg.webp*/
.Children_section .container {
    height: 100%;
}
.Children_container p {
    font-family: 'poppins', sans-serif;
    color: rgb(92, 91, 91);
    font-size: 1rem;
}
.bg-image img {
    height: 50%;
}
.Children_container .title {
    font-size: 1rem;
    padding: 0.5rem;
    color: var(--brown);
}
.Children_container .title2 {
    padding-bottom: 2rem;
}